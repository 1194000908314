import { AppointmentRequestStatus } from '@agentero/models';
import { CarrierAppointmentStatus } from '@agentero/models/carrierappointment';
import { InsuranceType } from '@agentero/models/opportunity';
import { PremiumTerm, QuotePolicyComponentType } from '@agentero/models/quote';
import {
	Carrier,
	Gender,
	LineOfAuthority,
	LineOfBusiness,
	MaritalStatus
} from '@agentero/models/shared';
import { TagColor } from '@agentero/ui';

// POLICY

export const insuranceTypeLabelKeys: { [key in InsuranceType]: string } = {
	[InsuranceType.AtvOffRoadVehicle]: 'insuranceTypes.atvOffRoadVehicle',
	[InsuranceType.Commercial]: 'insuranceTypes.commercial',
	[InsuranceType.CommercialAuto]: 'insuranceTypes.commercialAuto',
	[InsuranceType.Condo]: 'insuranceTypes.condo',
	[InsuranceType.DwellingFire]: 'insuranceTypes.dwellingFire',
	[InsuranceType.Flood]: 'insuranceTypes.flood',
	[InsuranceType.Homeonwners]: 'insuranceTypes.homeowners',
	[InsuranceType.Landlord]: 'insuranceTypes.landlord',
	[InsuranceType.Life]: 'insuranceTypes.life',
	[InsuranceType.MobileHomeowners]: 'insuranceTypes.mobileHomeowners',
	[InsuranceType.Motorcycle]: 'insuranceTypes.motorcycle',
	[InsuranceType.Other]: 'insuranceTypes.other',
	[InsuranceType.PersonalAuto]: 'insuranceTypes.personalAuto',
	[InsuranceType.PersonalUmbrella]: 'insuranceTypes.umbrella',
	[InsuranceType.Renter]: 'insuranceTypes.renter',
	[InsuranceType.InlandMarine]: 'insuranceTypes.inlandMarine',
	[InsuranceType.UndefinedInsuranceType]: 'insuranceTypes.undefinedInsuranceType',
	[InsuranceType.ProfessionalLiability]: 'insuranceTypes.professionalLiability',
	[InsuranceType.GeneralLiability]: 'insuranceTypes.generalLiability',
	[InsuranceType.BusinessOwnerPolicy]: 'insuranceTypes.businessOwnerPolicy',
	[InsuranceType.WorkersCompesation]: 'insuranceTypes.workersCompensation',
	[InsuranceType.CommercialProperty]: 'insuranceTypes.commercialProperty',
	[InsuranceType.CommercialUmbrella]: 'insuranceTypes.commercialUmbrella',
	[InsuranceType.DirectorsAndOfficers]: 'insuranceTypes.directorsAndOfficers',
	[InsuranceType.ErrorsAndOmissions]: 'insuranceTypes.errorsAndOmissions',
	[InsuranceType.ExcessLiability]: 'insuranceTypes.excessLiability',
	[InsuranceType.CyberLiability]: 'insuranceTypes.cyberLiability',
	[InsuranceType.RentGuarantee]: 'insuranceTypes.rentGuarantee',
	[InsuranceType.ExcessAndSurplusCommercialCasualty]:
		'insuranceTypes.excessAndSurplusCommercialCasualty',
	[InsuranceType.SpecialEvents]: 'insuranceTypes.specialEvents',
	[InsuranceType.SpecialtyCommercial]: 'insuranceTypes.specialtyCommercial',
	[InsuranceType.CommercialFlood]: 'insuranceTypes.commercialFlood',
	[InsuranceType.ParametricEarthquake]: 'insuranceTypes.parametricEarthquake',
	[InsuranceType.HomeAndElectronicProtection]: 'insuranceTypes.homeAndElectronicProtection',
	[InsuranceType.RecreationalVehicles]: 'insuranceTypes.recreationalVehicles'
};

// APPOINTMENTS

export const appointmentStatusKeys: {
	[key in Exclude<CarrierAppointmentStatus, CarrierAppointmentStatus.Unavailable>]: string;
} = {
	[CarrierAppointmentStatus.Available]: 'status.eligible',
	[CarrierAppointmentStatus.AvailableNew]: 'status.eligible',
	[CarrierAppointmentStatus.InProgress]: 'status.inProgress',
	[CarrierAppointmentStatus.Rejected]: 'status.rejected',
	[CarrierAppointmentStatus.Appointed]: 'status.appointed',
	[CarrierAppointmentStatus.AnotherAggregator]: 'status.transfer',
	[CarrierAppointmentStatus.AnotherAggregatorNew]: 'status.transfer',
	[CarrierAppointmentStatus.Pending]: 'status.pending',
	[CarrierAppointmentStatus.NotEligible]: 'status.rejected',
	[CarrierAppointmentStatus.NonSolicitation]: 'status.rejected',
	[CarrierAppointmentStatus.Terminated]: 'status.rejected',
	[CarrierAppointmentStatus.Hide]: 'status.rejected',
	[CarrierAppointmentStatus.UndefinedStatus]: 'status.rejected'
};

export const appointmentTagColorKeys: {
	[key in Exclude<CarrierAppointmentStatus, CarrierAppointmentStatus.Unavailable>]: TagColor;
} = {
	[CarrierAppointmentStatus.Available]: 'blue',
	[CarrierAppointmentStatus.AvailableNew]: 'blue',
	[CarrierAppointmentStatus.InProgress]: 'orange',
	[CarrierAppointmentStatus.Rejected]: 'red',
	[CarrierAppointmentStatus.Appointed]: 'green',
	[CarrierAppointmentStatus.AnotherAggregator]: 'pink',
	[CarrierAppointmentStatus.AnotherAggregatorNew]: 'pink',
	[CarrierAppointmentStatus.Pending]: 'gray',
	[CarrierAppointmentStatus.NotEligible]: 'gray',
	[CarrierAppointmentStatus.NonSolicitation]: 'gray',
	[CarrierAppointmentStatus.Terminated]: 'gray',
	[CarrierAppointmentStatus.Hide]: 'gray',
	[CarrierAppointmentStatus.UndefinedStatus]: 'gray'
};

export const appointmentStatusTooltips: {
	[key in Exclude<CarrierAppointmentStatus, CarrierAppointmentStatus.Unavailable>]: string;
} = {
	[CarrierAppointmentStatus.Available]: null,
	[CarrierAppointmentStatus.AvailableNew]: null,
	[CarrierAppointmentStatus.InProgress]: 'status.inProgressTooltip',
	[CarrierAppointmentStatus.Rejected]: null,
	[CarrierAppointmentStatus.Appointed]: null,
	[CarrierAppointmentStatus.AnotherAggregator]: null,
	[CarrierAppointmentStatus.AnotherAggregatorNew]: null,
	[CarrierAppointmentStatus.Pending]: 'status.pendingTooltip',
	[CarrierAppointmentStatus.NotEligible]: null,
	[CarrierAppointmentStatus.NonSolicitation]: null,
	[CarrierAppointmentStatus.Terminated]: null,
	[CarrierAppointmentStatus.Hide]: null,
	[CarrierAppointmentStatus.UndefinedStatus]: null
};

// CARRIERS

export const appointmentRequestStatusKeys: {
	[key in AppointmentRequestStatus]: string;
} = {
	[AppointmentRequestStatus.NotRequested]: 'notRequested',
	[AppointmentRequestStatus.InProgress]: 'inProgress',
	[AppointmentRequestStatus.NotApproved]: 'notApproved',
	[AppointmentRequestStatus.Approved]: 'approved',
	[AppointmentRequestStatus.NotProcessed]: 'notProcessed',
	[AppointmentRequestStatus.TerminationInProgress]: 'terminationInProgress',
	[AppointmentRequestStatus.Terminated]: 'terminated'
};

// MISC

export const states = [
	{ name: 'Alabama', abbreviation: 'AL' },
	{ name: 'Alaska', abbreviation: 'AK' },
	{ name: 'Arizona', abbreviation: 'AZ' },
	{ name: 'Arkansas', abbreviation: 'AR' },
	{ name: 'California', abbreviation: 'CA' },
	{ name: 'Colorado', abbreviation: 'CO' },
	{ name: 'Connecticut', abbreviation: 'CT' },
	{ name: 'District of Columbia', abbreviation: 'DC' },
	{ name: 'Delaware', abbreviation: 'DE' },
	{ name: 'Florida', abbreviation: 'FL' },
	{ name: 'Georgia', abbreviation: 'GA' },
	{ name: 'Hawaii', abbreviation: 'HI' },
	{ name: 'Idaho', abbreviation: 'ID' },
	{ name: 'Illinois', abbreviation: 'IL' },
	{ name: 'Indiana', abbreviation: 'IN' },
	{ name: 'Iowa', abbreviation: 'IA' },
	{ name: 'Kansas', abbreviation: 'KS' },
	{ name: 'Kentucky', abbreviation: 'KY' },
	{ name: 'Louisiana', abbreviation: 'LA' },
	{ name: 'Maine', abbreviation: 'ME' },
	{ name: 'Maryland', abbreviation: 'MD' },
	{ name: 'Massachusetts', abbreviation: 'MA' },
	{ name: 'Michigan', abbreviation: 'MI' },
	{ name: 'Minnesota', abbreviation: 'MN' },
	{ name: 'Mississippi', abbreviation: 'MS' },
	{ name: 'Missouri', abbreviation: 'MO' },
	{ name: 'Montana', abbreviation: 'MT' },
	{ name: 'Nebraska', abbreviation: 'NE' },
	{ name: 'Nevada', abbreviation: 'NV' },
	{ name: 'New Hampshire', abbreviation: 'NH' },
	{ name: 'New Jersey', abbreviation: 'NJ' },
	{ name: 'New Mexico', abbreviation: 'NM' },
	{ name: 'New York', abbreviation: 'NY' },
	{ name: 'North Carolina', abbreviation: 'NC' },
	{ name: 'North Dakota', abbreviation: 'ND' },
	{ name: 'Ohio', abbreviation: 'OH' },
	{ name: 'Oklahoma', abbreviation: 'OK' },
	{ name: 'Oregon', abbreviation: 'OR' },
	{ name: 'Pennsylvania', abbreviation: 'PA' },
	{ name: 'Puerto Rico', abbreviation: 'PR' },
	{ name: 'Rhode Island', abbreviation: 'RI' },
	{ name: 'South Carolina', abbreviation: 'SC' },
	{ name: 'South Dakota', abbreviation: 'SD' },
	{ name: 'Tennessee', abbreviation: 'TN' },
	{ name: 'Texas', abbreviation: 'TX' },
	{ name: 'Utah', abbreviation: 'UT' },
	{ name: 'Vermont', abbreviation: 'VT' },
	{ name: 'Virginia', abbreviation: 'VA' },
	{ name: 'Washington', abbreviation: 'WA' },
	{ name: 'West Virginia', abbreviation: 'WV' },
	{ name: 'Wisconsin', abbreviation: 'WI' },
	{ name: 'Wyoming', abbreviation: 'WY' }
];

export const statesKeys: { [key: string]: string } = {
	['AL']: 'Alabama',
	['AK']: 'Alaska',
	['AZ']: 'Arizona',
	['AR']: 'Arkansas',
	['CA']: 'California',
	['CO']: 'Colorado',
	['CT']: 'Connecticut',
	['DE']: 'Delaware',
	['FL']: 'Florida',
	['GA']: 'Georgia',
	['HI']: 'Hawaii',
	['ID']: 'Idaho',
	['IL']: 'Illinois',
	['IN']: 'Indiana',
	['IA']: 'Iowa',
	['KS']: 'Kansas',
	['KY']: 'Kentucky',
	['LA']: 'Louisiana',
	['ME']: 'Maine',
	['MD']: 'Maryland',
	['MA']: 'Massachusetts',
	['MI']: 'Michigan',
	['MN']: 'Minnesota',
	['MS']: 'Missisipi',
	['MO']: 'Missouri',
	['MT']: 'Montana',
	['NE']: 'Nebraska',
	['NV']: 'Nevada',
	['NH']: 'New Hampshire',
	['NJ']: 'New Jersey',
	['NM']: 'New Mexico',
	['NY']: 'New York',
	['NC']: 'North Carolina',
	['ND']: 'North Dakota',
	['OH']: 'Ohio',
	['OK']: 'Oklahoma',
	['OR']: 'Oregon',
	['PA']: 'Pennsylvania',
	['RI']: 'Rhode Island',
	['SC']: 'South Carolina',
	['SD']: 'South Dakota',
	['TN']: 'Tennessee',
	['TX']: 'Texas',
	['UT']: 'Utah',
	['VT']: 'Vermont',
	['VA']: 'Virginia',
	['WA']: 'Washington',
	['WV']: 'West Virginia',
	['WI']: 'Wisconsin',
	['WY']: 'Wyoming'
};

export const carrierDescriptionLabelKeys: { [key in Carrier]?: string[] } = {
	[Carrier.Openly]: [
		'carriers.description.openly.1',
		'carriers.description.openly.2',
		'carriers.description.openly.3',
		'carriers.description.openly.4'
	],
	[Carrier.Clearcover]: ['carriers.description.clearcover.1', 'carriers.description.clearcover.2'],
	[Carrier.AonEdge]: [
		'carriers.description.aonEdge.1',
		'carriers.description.aonEdge.2',
		'carriers.description.aonEdge.3',
		'carriers.description.aonEdge.4'
	],
	[Carrier.HavenLife]: [
		'carriers.description.havenLife.1',
		'carriers.description.havenLife.2',
		'carriers.description.havenLife.3',
		'carriers.description.havenLife.4'
	],
	[Carrier.Elephant]: ['carriers.description.elephant.1'],
	[Carrier.Stillwater]: ['carriers.description.stillwater.1', 'carriers.description.stillwater.2'],
	[Carrier.Lemonade]: ['carriers.description.lemonade.1', 'carriers.description.lemonade.2']
};

export const genderLabelKeys: { [key in Gender]: string } = {
	[Gender.Male]: 'male',
	[Gender.Female]: 'female',
	[Gender.Unknown]: ''
};

export const maritalStatusLabelKeys: { [key in MaritalStatus]: string } = {
	[MaritalStatus.Divorced]: 'maritalStatus.divorced',
	[MaritalStatus.Married]: 'maritalStatus.married',
	[MaritalStatus.Separated]: 'maritalStatus.separated',
	[MaritalStatus.Single]: 'maritalStatus.single',
	[MaritalStatus.Widowed]: 'maritalStatus.widowed',
	[MaritalStatus.Civil]: 'maritalStatus.civil',
	[MaritalStatus.Unknown]: ''
};

export const lineOfBusinessLabelKeys: { [key in LineOfBusiness]: string } = {
	[LineOfBusiness.Commercial]: 'lineOfBusiness.commercial',
	[LineOfBusiness.Health]: 'lineOfBusiness.health',
	[LineOfBusiness.Life]: 'lineOfBusiness.life',
	[LineOfBusiness.PropertyAndCasualty]: 'lineOfBusiness.propertyAndCasualty',
	[LineOfBusiness.Personal]: 'lineOfBusiness.personal',
	[LineOfBusiness.PersonalAuto]: 'lineOfBusiness.personalAuto'
};

export const lineOfAuthorityLabelKeys: { [key in LineOfAuthority]: string } = {
	[LineOfAuthority.Commercial]: 'lineOfBusiness.commercial',
	[LineOfAuthority.LimitedCommercial]: 'lineOfAuthority.limitedCommercial',
	[LineOfAuthority.Health]: 'lineOfBusiness.health',
	[LineOfAuthority.Life]: 'lineOfBusiness.life',
	[LineOfAuthority.PropertyAndCasualty]: 'lineOfBusiness.propertyAndCasualty',
	[LineOfAuthority.Personal]: 'lineOfBusiness.personal',
	[LineOfAuthority.PersonalAuto]: 'lineOfBusiness.personalAuto',
	[LineOfAuthority.SurplusLines]: 'lineOfAuthority.surplusLines',
	[LineOfAuthority.Principal]: 'lineOfAuthority.principal',
	[LineOfAuthority.Other]: 'lineOfAuthority.other'
};

export const quotePolicyComponentTypeKeys: { [key in QuotePolicyComponentType]: string } = {
	//HOME
	[QuotePolicyComponentType.DwellingA]: 'dwellingA',
	[QuotePolicyComponentType.Deductible]: 'deductible',
	[QuotePolicyComponentType.PersonalLiability]: 'personalLiability',
	//AUTO
	[QuotePolicyComponentType.BodilyInjuryLiability]: 'bodilyInjuryLiability',
	[QuotePolicyComponentType.PropertyDamage]: 'propertyDamage',
	[QuotePolicyComponentType.BasicPersonalInjuryProtection]: 'basicPersonalInjuryProtection',
	[QuotePolicyComponentType.UninsuredMotoristBodilyInjury]: 'uninsuredMotoristBodilyInjury',
	[QuotePolicyComponentType.UnderInsuredMotoristBodilyInjury]: 'underInsuredMotoristBodilyInjury',
	[QuotePolicyComponentType.UninsuredUnderinsuredMotoristBodilyInjury]:
		'uninsuredUnderinsuredMotoristBodilyInjury',
	[QuotePolicyComponentType.UninsuredMotoristPropertyDamage]: 'uninsuredMotoristPropertyDamage',
	[QuotePolicyComponentType.Comprehensive]: 'comprehensive',
	[QuotePolicyComponentType.Collision]: 'collision'
};

export const premiumTermLabelKeys: { [key in PremiumTerm]: string } = {
	[PremiumTerm.Annual]: 'premiumTerm.annual',
	[PremiumTerm.SixMonths]: 'premiumTerm.sixMonths'
};
