import { MaritalStatus as MaritalStatusRisk } from '@agentero/grpc-clients/grpc/shared/risk';

export enum MaritalStatus {
	Single = 'single',
	Married = 'married',
	Divorced = 'divorced',
	Separated = 'separated',
	Widowed = 'widowed',
	Civil = 'civil',
	Unknown = 'unknown'
}

export const maritalStatusToProtoRiskMapping: {
	[key in Exclude<MaritalStatus, MaritalStatus.Unknown>]: MaritalStatusRisk;
} = {
	[MaritalStatus.Divorced]: MaritalStatusRisk.MARITAL_STATUS_DIVORCED,
	[MaritalStatus.Married]: MaritalStatusRisk.MARITAL_STATUS_MARRIED,
	[MaritalStatus.Separated]: MaritalStatusRisk.MARITAL_STATUS_SEPARATED,
	[MaritalStatus.Single]: MaritalStatusRisk.MARITAL_STATUS_SINGLE,
	[MaritalStatus.Widowed]: MaritalStatusRisk.MARITAL_STATUS_WIDOWED,
	[MaritalStatus.Civil]: MaritalStatusRisk.MARITAL_STATUS_CIVIL
};

export const parseMaritalStatusToProtoRisk = (maritalStatus: MaritalStatus): MaritalStatusRisk => {
	if (maritalStatus === MaritalStatus.Unknown)
		throw new Error('Invalid MaritalStatus.Unknown value');

	return maritalStatusToProtoRiskMapping[maritalStatus];
};

export const maritalStatusFromProtoRiskMapping: {
	[key in MaritalStatusRisk]: MaritalStatus | null;
} = {
	[MaritalStatusRisk.MARITAL_STATUS_UNSPECIFIED]: null,
	[MaritalStatusRisk.MARITAL_STATUS_DIVORCED]: MaritalStatus.Divorced,
	[MaritalStatusRisk.MARITAL_STATUS_MARRIED]: MaritalStatus.Married,
	[MaritalStatusRisk.MARITAL_STATUS_SEPARATED]: MaritalStatus.Separated,
	[MaritalStatusRisk.MARITAL_STATUS_SINGLE]: MaritalStatus.Single,
	[MaritalStatusRisk.MARITAL_STATUS_WIDOWED]: MaritalStatus.Widowed,
	[MaritalStatusRisk.MARITAL_STATUS_CIVIL]: MaritalStatus.Civil
};

export const parseMaritalStatusFromProtoRisk = (maritalStatus: MaritalStatusRisk): MaritalStatus =>
	maritalStatusFromProtoRiskMapping[maritalStatus]!;
