import { Gender as GenderRisk } from '@agentero/grpc-clients/grpc/shared/risk';
import { Gender as GenderFromProto } from '@agentero/service-clients/producerflow/shared/onboard';

export enum Gender {
	Unknown = 'unknown',
	Male = 'male',
	Female = 'female'
}

const genderMappingToProto: { [key in Exclude<Gender, Gender.Unknown>]: GenderRisk } = {
	[Gender.Female]: GenderRisk.GENDER_FEMALE,
	[Gender.Male]: GenderRisk.GENDER_MALE
};

export const parseGenderToProtoRisk = (gender: Gender): GenderRisk => {
	if (gender === Gender.Unknown) throw new Error('Invalid Gender.Unknown value');

	return genderMappingToProto[gender];
};

const genderMappingFromProto: { [key in GenderRisk]: Gender | null } = {
	[GenderRisk.GENDER_UNSPECIFIED]: null,
	[GenderRisk.GENDER_FEMALE]: Gender.Female,
	[GenderRisk.GENDER_MALE]: Gender.Male
};

export const parseGenderFromProtoRisk = (gender: GenderRisk): Gender =>
	genderMappingFromProto[gender]!;

const genderLabelMap = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.Unknown]: 'Unknown'
};

export const genderOptions = [
	{ label: genderLabelMap[Gender.Male], value: Gender.Male },
	{ label: genderLabelMap[Gender.Female], value: Gender.Female }
];

export const parseGenderProducerFlow = (gender: Gender): GenderFromProto => {
	return {
		[Gender.Unknown]: GenderFromProto.UNSPECIFIED,
		[Gender.Male]: GenderFromProto.MALE,
		[Gender.Female]: GenderFromProto.FEMALE
	}[gender];
};

export const parseGenderProducerFlowFromProto = (gender: GenderFromProto): Gender | undefined => {
	return {
		[GenderFromProto.UNSPECIFIED]: undefined,
		[GenderFromProto.MALE]: Gender.Male,
		[GenderFromProto.FEMALE]: Gender.Female
	}[gender];
};

export const getLabelFromGender = (gender: Gender): string => {
	return genderLabelMap[gender];
};
