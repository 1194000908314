import { LineOfBusiness as LineOfBusinessProto } from '@agentero/grpc-clients/grpc/agency-fe';

export enum LineOfBusiness {
	Life = 'life',
	Health = 'health',
	Commercial = 'commercial',
	PropertyAndCasualty = 'property_and_casualty',
	Personal = 'personal',
	PersonalAuto = 'personal_auto'
}

export const parseLineOfBusinessFromProto = (lob: LineOfBusinessProto): LineOfBusiness =>
	lineOfBusinessFromProtoMapping[lob];

const lineOfBusinessFromProtoMapping: { [key in LineOfBusinessProto]: LineOfBusiness } = {
	[LineOfBusinessProto.HEALTH]: LineOfBusiness.Health,
	[LineOfBusinessProto.COMMERCIAL]: LineOfBusiness.Commercial,
	[LineOfBusinessProto.LIFE]: LineOfBusiness.Life,
	[LineOfBusinessProto.PROPERTY_AND_CASUALTY]: LineOfBusiness.PropertyAndCasualty,
	[LineOfBusinessProto.PERSONAL]: LineOfBusiness.Personal,
	[LineOfBusinessProto.PERSONAL_AUTO]: LineOfBusiness.PersonalAuto
};

const lineOfBusinessToProtoMapping: {
	[key in LineOfBusiness]: LineOfBusinessProto;
} = {
	[LineOfBusiness.Commercial]: LineOfBusinessProto.COMMERCIAL,
	[LineOfBusiness.Health]: LineOfBusinessProto.HEALTH,
	[LineOfBusiness.Life]: LineOfBusinessProto.LIFE,
	[LineOfBusiness.PropertyAndCasualty]: LineOfBusinessProto.PROPERTY_AND_CASUALTY,
	[LineOfBusiness.Personal]: LineOfBusinessProto.PERSONAL,
	[LineOfBusiness.PersonalAuto]: LineOfBusinessProto.PERSONAL_AUTO
};
export const parseLineOfBusinessToProto = (lob: LineOfBusiness): LineOfBusinessProto =>
	lineOfBusinessToProtoMapping[lob];
