export enum QuotePolicyComponentType {
	//Home
	DwellingA = 'dwelling_A',
	Deductible = 'deductible',
	PersonalLiability = 'personal-liability',
	//Auto
	BodilyInjuryLiability = 'bodily-injury-liability',
	PropertyDamage = 'property-damage',
	BasicPersonalInjuryProtection = 'basic-personal-injury-protection',
	UninsuredMotoristBodilyInjury = 'uninsured-motorist-bodily-injury',
	UnderInsuredMotoristBodilyInjury = 'under-insured-motorist-bodily-injury',
	UninsuredUnderinsuredMotoristBodilyInjury = 'uninsured-underinsured-motorist-bodily-injury',
	UninsuredMotoristPropertyDamage = 'uninsured-motorist-property-damage',
	Comprehensive = 'comprehensive',
	Collision = 'collision'
}
