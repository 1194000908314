import { PremiumTerm as PremiumTermProto } from '@agentero/grpc-clients/grpc/shared/rater';

export enum PremiumTerm {
	Annual,
	SixMonths
}

const premiumTermFromProtoMapping: { [key in PremiumTermProto]: PremiumTerm } = {
	[PremiumTermProto.ANNUAL]: PremiumTerm.Annual,
	[PremiumTermProto.SIX_MONTHS]: PremiumTerm.SixMonths
};

export const parsePremiumTermFromProto = (premiumTerm: PremiumTermProto) =>
	premiumTermFromProtoMapping[premiumTerm];

const premiumTermToProtoMapping: { [key in PremiumTerm]: PremiumTermProto } = {
	[PremiumTerm.Annual]: PremiumTermProto.ANNUAL,
	[PremiumTerm.SixMonths]: PremiumTermProto.SIX_MONTHS
};

export const parsePremiumTermToProto = (premiumTerm: PremiumTerm) =>
	premiumTermToProtoMapping[premiumTerm];
